@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('./assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Muli';
  src: url('./assets/fonts/Mulish/static/Mulish-Bold.ttf') format('truetype')
}

.font-inter {
  font-family: 'Inter-Regular';
}

p {
  font-family: 'Inter-Regular';
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px gray;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 4px;
}